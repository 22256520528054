import type { FormEntry } from "@silexpert/core";
import { Vat } from "@silexpert/core";

export type ManualVat = {
  idVat: number;
  amount: number;
}[];

export type ManualVatElement = {
  idVat: number;
  amount: number;
};

export const getVatAmount = ({
  entry,
  amount,
  roundToCents,
}: {
  entry: FormEntry;
  amount: number;
  roundToCents: boolean;
}): number | null => {
  const serverInfoStore = useServerInfoStore();
  const utils = useAnnotationUtilsComposable();

  const realVatId = utils.getVatIdFromFormEntryVat(entry.vat, entry);
  const vats = serverInfoStore.vats ?? [];
  const matchingRealVat = vats.find((vat) => vat.idTva === realVatId);
  if (!isDefined(matchingRealVat)) {
    return null;
  }

  let vatAmount: number | null = null;

  // percentage or intracom vat
  if (
    [
      Vat.TX_5_5.idTva,
      Vat.TX_10.idTva,
      Vat.TX_20.idTva,
      Vat.TX_2_1.idTva,
      Vat.TX_8_5.idTva,
    ].includes(matchingRealVat.idTva)
  ) {
    vatAmount = (Math.abs(amount) / (100 + matchingRealVat.value)) * matchingRealVat.value;
  }

  // margin vat
  if (matchingRealVat.idTva === Vat.MARGE.idTva) {
    if (!isDefined(entry.amountAllTaxIncludedPurchase)) {
      return null;
    }

    const percentageVatId = entry.vat as number;
    const matchingPercentageVat = vats.find((vat) => vat.idTva === percentageVatId);
    if (!isDefined(matchingPercentageVat)) {
      return null;
    }

    const sellAmount = amount - entry.amountAllTaxIncludedPurchase;
    vatAmount =
      (Math.abs(sellAmount) / (100 + matchingPercentageVat.value)) * matchingPercentageVat.value;
  }

  // multiple/manual vat
  if (matchingRealVat.idTva === Vat.MANUAL.idTva) {
    if (!isArray(entry.vat)) {
      return null;
    }

    const vats = (entry.vat ?? []) as ManualVat;

    vats.forEach((vat) => {
      vatAmount = (vatAmount ?? 0) + vat.amount;
    });
  }

  // intracom or importation vat
  if (entry.isVatImportation || entry.isVatIntracom) {
    vatAmount = Math.abs(amount) * (matchingRealVat.value / 100);
  }

  // round vatAmount to cents
  if (isNumber(vatAmount) && roundToCents) {
    vatAmount = Math.round((vatAmount + Number.EPSILON) * 100) / 100;
  }

  return vatAmount;
};
